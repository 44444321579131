import { useI18n } from 'vue-i18n';
import { toValue, until, useBreakpoints, useCssVar, useElementSize, useMediaControls, useScroll, watchImmediate } from "@vueuse/core";
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref } from "vue";
import { useComponentChain } from "@/utils/errorReportingUtils.js";
import { useRouter } from "vue-router";

export function useShared() {
  const bp = useBreakpoints({ mobile: 0, desktop: 768 }).active();

  return {
    ...useI18n({
      inheritLocale: true,
      useScope: 'local',
    }),
    componentInfo: {
      chain: useComponentChain(),
    },
    mobile: computed(() => bp.value === 'mobile'),
    desktop: computed(() => bp.value === 'desktop'),
  };
}

export function redirectUnless(cond, route) {
  onBeforeMount(() => {
    if (!cond) {
      const router = useRouter();
      router.push(route);
    }
  });
}

export function useBodyClass(classname) {
  onMounted(() => document.body.classList.add(classname));
  onBeforeUnmount(() => document.body.classList.remove(classname));
}

export function setBodyCssVar(varname, value) {
  const cssVar = useCssVar(varname, document.body);
  cssVar.value = value;
}

export function setMobileBodyCssVar(varname, value) {
  const { mobile } = useShared();
  const cssVar = useCssVar(varname, document.body);
  watchImmediate(mobile, on => cssVar.value = on ? value : null);
}

export function optionList(arr) {
  return arr.map(v => ({ value: v, label: String(v) }));
}

export function scrollBehavior(to, from, savedPosition) {
  if (from.path === to.path) {
    return { x: window.scrollX, y: window.scrollY };
  }

  return savedPosition ? savedPosition : { left: 0, top: 0 }
}

export function useSmoothScroll() {
  onMounted(() => document.querySelector('html').style.scrollBehavior = 'smooth');
  onBeforeUnmount(() => document.querySelector('html').style.scrollBehavior = 'unset');
}

export function useVideoControls(target, options) {
  const controls = useMediaControls(target, options);
  const played = ref(false);

  until(controls.playing).toBeTruthy().then(() => played.value = true);

  const play = () => {
    toValue(target).play().catch(console.error);
  }

  const mutedPlay = () => {
    controls.muted.value = true;
    toValue(target).play().catch(console.error);
  }

  const pause = () => {
    toValue(target).pause();
  }

  return {
    ...controls, played, play, mutedPlay, pause
  };
}

export function useElementScroll(el) {
  const { y } = useScroll(el);
  const { height } = useElementSize(el);
  const yRemaining = ref(0);

  watchImmediate([y, height], () => yRemaining.value = el.value ? el.value.scrollHeight - el.value.clientHeight - el.value.scrollTop : 0);

  return { y, yRemaining };
}